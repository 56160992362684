import React, { useState } from 'react';
import {useNavigate} from "react-router-dom";
import { useAtom } from 'jotai';
import { celebPermissionAtom } from '../../../atoms/celeb';
import FavoriteButton from "../../button/FavoriteButton";
import {formatNumber} from "../../../utils/utils";
import styled from "styled-components";
import { colors } from '../../../styles/color';
import LimitedPermissionRequestModals from "../../modals/LimitedPermissionRequestModals";
import PermissionPendingSuccessModals from '../../modals/PermissionPendingSuccessModals';
import { ECelebType } from '../celebCard/CelebCard';
import {
	sendLimitedCelebPermission,
	putLimitedCelebPermission,
	sendLimitedPermissionPendingToAgency,
	sendLimitedPermissionPendingToAligo
} from '../../../api/celebPermission';
import ReactDOM from 'react-dom';

// GTM 타입 선언
declare global {
  interface Window {
    dataLayer?: any[];
  }
}

const CelebInfo = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 16px;
	z-index: 2;
	color: white;

	.name {
		color: var(--grey-white, #FFF);
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 120%;
		margin-bottom: 8px;
		text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
		white-space: nowrap; // 텍스트가 길어도 한줄로 표시
	}

	.stats {
		display: flex;
		align-items: center;
		gap: 12px;

		.stat-item {
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: 14px;
			color: white;

			img {
				width: 16px;
				height: 16px;
				filter: brightness(0) invert(1);
			}
		}
	}
`;

const Card = styled.div`
	display: flex;
	width: 240px;
	height: 240px;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	border-radius: 8px;
	margin: 0 auto;

	.image-container {
		position: relative;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0) 0%,
				rgba(0, 0, 0, 0.5) 50%,
				rgba(0, 0, 0, 0.8) 100%
			);
			z-index: 1;
		}
	}
`;

const FavoriteIconWrapper = styled.div`
	position: absolute;
	top: 8px;
	left: 8px;
	z-index: 2;
`;

interface CelebPermissions {
	[key: string]: string;
}

interface CelebCardProps {
	celeb: {
		id: string;
		name: string;
		instagramFollowersCnt: number;
		searchVolume: number;
		celebMainImg: string;
		isLike?: boolean;
		type: ECelebType;
		limitedCelebPermission: string;
	};
	onFavoriteUpdate: (id: string, status: boolean) => void;
}

const ContactNowCelebCard: React.FC<CelebCardProps> = ({ celeb, onFavoriteUpdate }) => {
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showStatusModal, setShowStatusModal] = useState(false);
	const [requestStatus, setRequestStatus] = useState<'pending' | 'success' | 'rejected'>('pending');
	const [permissions, setPermissions] = useAtom(celebPermissionAtom);

	const handleCelebClick = (e: React.MouseEvent) => {
		const target = e.target as HTMLElement;
		if (target.closest('button') || target.tagName.toLowerCase() === 'button') {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		// GTM 이벤트 추가
		const gtmEvent = {
			event: "click_celeb",
			celeb_id: celeb.id,
			is_limited: celeb.type === ECelebType.LIMITED,
			is_trending: true,
			timestamp: new Date().toISOString()
		};

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(gtmEvent);

		if (requestStatus === 'success'){
			setRequestStatus('success')
			setRequestStatus('pending');
			setShowStatusModal(true);
			return;
		}
		if (celeb.type === ECelebType.LIMITED) {
			const currentPermission = permissions[celeb.id] || celeb.limitedCelebPermission;

			if (currentPermission === 'PENDING') {
				setRequestStatus('pending');
				setShowStatusModal(true);
				return;
			} else if (currentPermission === 'REJECTED') {
				setRequestStatus('rejected');
				setShowStatusModal(true);
				return;
			} else if (currentPermission === null) {
				setShowModal(true);
				return;
			}
		}
		navigate(`/celebs/${celeb.id}`);
	};

	const handleModalClose = (e: React.MouseEvent) => {
		e.preventDefault();
		setShowModal(false);
	};

	const handleStatusModalClose = (e: React.MouseEvent) => {
		e.preventDefault();
		setShowStatusModal(false);
	};

	const handleModalSubmit = async (e: React.MouseEvent) => {
		e.preventDefault();
		setShowModal(false);
		setIsLoading(true);

		try {
			// GTM 이벤트 추가
			const gtmEvent = {
				event: "request_permission",
				celeb_id: celeb.id,
				is_trending: true,
				timestamp: new Date().toISOString()
			};

			window.dataLayer = window.dataLayer || [];
			window.dataLayer.push(gtmEvent);
			console.log('GTM Event sent');

			// 권한 요청 상태를 PENDING으로 변경
			const permissionResponse = await putLimitedCelebPermission(celeb.id);

			if (permissionResponse) {
				// Agency와 Aligo 모두에게 이메일 발송
				await Promise.all([
					sendLimitedPermissionPendingToAgency(celeb.id),
					sendLimitedPermissionPendingToAligo(celeb.id)
				]);

				setRequestStatus('success');
				setShowStatusModal(true);
				// 글로벌 스테이트 업데이트
				setPermissions((prev: CelebPermissions) => ({
					...prev,
					[celeb.id]: 'PENDING'
				}));
			}
		} catch (error: any) {
			console.error('Error submitting permission request:', error);
			setShowModal(true);
		} finally {
			setIsLoading(false);
		}
	};

	const getStatusTitle = () => {
		switch (requestStatus) {
			case 'success':
				return 'Permission Request Submitted';
			case 'rejected':
				return 'unfortunately, your request has not been approved at this time';
			default:
				return 'Your request is being processed.';
		}
	};

	return (
		<>
			<Card
				onClick={handleCelebClick}
				data-is-trending={true}
				data-celeb-id={celeb.id}
				data-is-limited={celeb.type === ECelebType.LIMITED}
				className="celeb"
			>
				<FavoriteIconWrapper onClick={(e) => e.stopPropagation()}>
					<FavoriteButton
						id={celeb.id}
						size={32}
						status={celeb.isLike || false}
						color={{
							true: colors.aligo[600],
							false: "white"
						}}
						onChange={(id, newStatus) => {
							// GTM 이벤트 전송
							window.dataLayer = window.dataLayer || [];
							window.dataLayer.push({
								event: "like_celeb",
								celeb_id: id,
								is_limited: celeb.type === ECelebType.LIMITED,
								is_trending: true
							});
							console.log('GTM Event sent');

							onFavoriteUpdate(id, newStatus);
						}}
					/>
				</FavoriteIconWrapper>
				<div className="image-container">
					<img src={celeb.celebMainImg} alt={celeb.name} />
					<div className="overlay" />
					<CelebInfo>
						<div className="name">{celeb.name}</div>
					</CelebInfo>
				</div>
			</Card>
			{showModal && ReactDOM.createPortal(
				<LimitedPermissionRequestModals
					isVisible={showModal}
					onClose={handleModalClose}
					onSubmit={handleModalSubmit}
					isLoading={isLoading}
					celebName={celeb.name}
				/>,
				document.body
			)}
			{showStatusModal && ReactDOM.createPortal(
				<PermissionPendingSuccessModals
					isVisible={showStatusModal}
					onClose={handleStatusModalClose}
					titleMessage={getStatusTitle()}
					status={requestStatus}
					celebId={celeb.id}
				/>,
				document.body
			)}
		</>
	);
};

export default ContactNowCelebCard;
