import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import styled from "styled-components";
import { Navigate } from "react-router-dom";
import StyledButton from "../../button/StyledButton";
import { userType } from "../../interfaces/auth.interfaces";
import RegisterInput from "../../input/RegisterInput";
import PasswordInput, {ConfirmPasswordInput} from "../../input/PasswordInput"; 
import EmailVerifyInput from "../../input/EmailVerifyInput";
import { useSignupMutation } from "../../../hooks/useSignup";
import { useAtom } from "jotai";
import { signupAtom } from "../../../atoms/auth";
import Modals from "../../modals/Modals";
import { colors } from "../../../styles/color";
import PhoneInput from '../../input/PhoneInput';

declare global {
  interface Window {
    dataLayer?: any[];
    lintrk?: (command: string, params: any) => void;
  }
}

const schema = z
.object({
  email: z.string().email({ message: "Please enter a valid email address." }),
  password: z
  .string()
  .min(8, { message: "Password must meet the required format." })
  .regex(/.{8,}$/, { message: "Password must meet the required format." }),
  passwordConfirm: z
  .string()
  .min(8, { message: "Password must meet the required format." }),
  phone: z.string(),
  companyName: z
  .string()
  .min(1, { message: "Please enter a valid company name." }),
  position: z.string(),
})
.refine((data) => data.password === data.passwordConfirm, {
  message: "Passwords do not match.",
  path: ["passwordConfirm"],
});

export interface SignupFormData {
  companyName: string;
  companyType: userType;
  position: string;
  marketAgree: boolean;
  email: string;
  password: string;
  passwordConfirm: string;
  phone: string;
}

const SignupFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 3.2rem;
  
`;

const SignupForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const methods = useForm<SignupFormData>({
    mode: 'onChange',
    defaultValues: {
      companyName: '',
      email: '',
      password: '',
      passwordConfirm: '',
      phone: '',
      position: ''
    },
    resolver: zodResolver(schema)
  });

  const closeModal = () => {
    setShowModal(false);
    setModalMessage("");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    watch,
    setValue,
  } = methods;

  const [isVerified, setIsVerified] = useState(false);
  const [signupFormData, setSignupFormData] = useAtom(signupAtom);

  const signupMutation = useSignupMutation(setError);

  const onSubmit = (data: SignupFormData) => {
    const formData = {
      ...data,
      companyType: signupFormData.companyType,
      marketAgree: signupFormData.marketAgree,
      phone: data.phone || '',
      position: data.position || ''
    };

    signupMutation.mutate(formData, {
      onSuccess: (response) => {
        // GTM 이벤트 전송
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'complete_sign_up',
          custom_user_id: response.data.user.id
        });
        console.log('GTM Event sent',response.data.user.id);
        // LinkedIn 전환 추적
        if (window.lintrk) {
          window.lintrk('track', { conversion_id: 21656209 });
          console.log("LinkedIn conversion tracked");
        }
      },
      onError: (error) => {
        console.error('Signup Error:', error);
      }
    });
  };

  const isFormValid = methods.formState.isValid &&
    methods.watch("email") &&
    methods.watch("password") &&
    methods.watch("passwordConfirm") &&
    methods.watch("companyName");
    // isVerified;

  if (!signupFormData.companyType) {
    return <Navigate to="../" />;
  }

  return (
    <>
      <FormProvider {...methods}>
        <SignupFormContainer onSubmit={methods.handleSubmit(onSubmit)}>
          <EmailVerifyInput
            label="Company Email"
            fieldName="email"
            placeholder="Email"
            register={register}
            errorMessage={errors.email?.message}
            required={true}
            disabled={isVerified}
          >
          </EmailVerifyInput>
          <PasswordInput
            label="Password"
            fieldName="password"
            placeholder="Password"
            register={register}
            errorMessage={errors.password?.message}
            required={true}
            helperText="Password must be at least 8 characters long."
          />
          <ConfirmPasswordInput
            label="Confirm Password"
            fieldName="passwordConfirm"
            placeholder="Confirm password"
            register={register}
            errorMessage={errors.passwordConfirm?.message}
            required={true}
          />
          <br/>
          <br/>
          <RegisterInput
            label="Company name"
            fieldName="companyName"
            type="text"
            placeholder="Company name or Brand name"
            register={register}
            errorMessage={errors.companyName?.message}
            required={true}
          />
          <RegisterInput
            label="Job position"
            fieldName="position"
            type="text"
            placeholder="Job position"
            register={register}
            errorMessage={errors.position?.message}
            required={false}
          />
          <PhoneInput
            register={methods.register}
            fieldName="phone"
            label="Phone number"
            required={false}
            errorMessage={methods.formState.errors.phone?.message}
          />
          <StyledButton
            type="submit"
            label="Continue"
            disabled={!isFormValid}
            style={{
              backgroundColor: !isFormValid ? colors.neutral['600'] : undefined,
              marginBottom: '40px',
              fontFamily: 'Inter'
            }}
          />
        </SignupFormContainer>
      </FormProvider>
      {showModal && (
        <Modals
          message={modalMessage}
          onClose={closeModal}
        />
      )}
    </>
  );
};

export default SignupForm;
